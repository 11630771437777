import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _07fbdbaa = () => interopDefault(import('../pages/check-email.vue' /* webpackChunkName: "pages/check-email" */))
const _5da8e32c = () => interopDefault(import('../pages/ian.vue' /* webpackChunkName: "pages/ian" */))
const _dd1e1206 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _2c821db4 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _7a93b91b = () => interopDefault(import('../pages/medications/index.vue' /* webpackChunkName: "pages/medications/index" */))
const _76fc7ab6 = () => interopDefault(import('../pages/password-updated.vue' /* webpackChunkName: "pages/password-updated" */))
const _c00b7020 = () => interopDefault(import('../pages/paylinks/index.vue' /* webpackChunkName: "pages/paylinks/index" */))
const _a0687820 = () => interopDefault(import('../pages/prescriptions/index.vue' /* webpackChunkName: "pages/prescriptions/index" */))
const _31b13682 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _179e1c1e = () => interopDefault(import('../pages/registered.vue' /* webpackChunkName: "pages/registered" */))
const _6de9c7ca = () => interopDefault(import('../pages/resend-confirmation.vue' /* webpackChunkName: "pages/resend-confirmation" */))
const _3113c175 = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _6ccb323f = () => interopDefault(import('../pages/settings.vue' /* webpackChunkName: "pages/settings" */))
const _1fcf2d08 = () => interopDefault(import('../pages/settings/close-account.vue' /* webpackChunkName: "pages/settings/close-account" */))
const _7c568434 = () => interopDefault(import('../pages/settings/export.vue' /* webpackChunkName: "pages/settings/export" */))
const _020f9a6c = () => interopDefault(import('../pages/settings/hospitals/index.vue' /* webpackChunkName: "pages/settings/hospitals/index" */))
const _79a3ff4e = () => interopDefault(import('../pages/settings/options.vue' /* webpackChunkName: "pages/settings/options" */))
const _9e338ade = () => interopDefault(import('../pages/settings/pharmacies.vue' /* webpackChunkName: "pages/settings/pharmacies" */))
const _62fce7c0 = () => interopDefault(import('../pages/settings/security.vue' /* webpackChunkName: "pages/settings/security" */))
const _60d952cb = () => interopDefault(import('../pages/settings/user.vue' /* webpackChunkName: "pages/settings/user" */))
const _570f1cba = () => interopDefault(import('../pages/settings/hospitals/new.vue' /* webpackChunkName: "pages/settings/hospitals/new" */))
const _597d9f57 = () => interopDefault(import('../pages/settings/hospitals/_id/index.vue' /* webpackChunkName: "pages/settings/hospitals/_id/index" */))
const _931c85d6 = () => interopDefault(import('../pages/settings/hospitals/_id/edit.vue' /* webpackChunkName: "pages/settings/hospitals/_id/edit" */))
const _5fd62f19 = () => interopDefault(import('../pages/wrapped.vue' /* webpackChunkName: "pages/wrapped" */))
const _5bead250 = () => interopDefault(import('../pages/embed/rx.vue' /* webpackChunkName: "pages/embed/rx" */))
const _7be05184 = () => interopDefault(import('../pages/prescriptions/new.vue' /* webpackChunkName: "pages/prescriptions/new" */))
const _07139009 = () => interopDefault(import('../pages/redirect/new.vue' /* webpackChunkName: "pages/redirect/new" */))
const _706d5834 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _c378b2b0 = () => interopDefault(import('../pages/paylinks/_slug.vue' /* webpackChunkName: "pages/paylinks/_slug" */))
const _429e638b = () => interopDefault(import('../pages/prescriptions/_signature.vue' /* webpackChunkName: "pages/prescriptions/_signature" */))
const _3a3e44ed = () => interopDefault(import('../pages/new-password/_uid/_token.vue' /* webpackChunkName: "pages/new-password/_uid/_token" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/check-email",
    component: _07fbdbaa,
    name: "check-email"
  }, {
    path: "/ian",
    component: _5da8e32c,
    name: "ian"
  }, {
    path: "/login",
    component: _dd1e1206,
    name: "login"
  }, {
    path: "/logout",
    component: _2c821db4,
    name: "logout"
  }, {
    path: "/medications",
    component: _7a93b91b,
    name: "medications"
  }, {
    path: "/password-updated",
    component: _76fc7ab6,
    name: "password-updated"
  }, {
    path: "/paylinks",
    component: _c00b7020,
    name: "paylinks"
  }, {
    path: "/prescriptions",
    component: _a0687820,
    name: "prescriptions"
  }, {
    path: "/register",
    component: _31b13682,
    name: "register"
  }, {
    path: "/registered",
    component: _179e1c1e,
    name: "registered"
  }, {
    path: "/resend-confirmation",
    component: _6de9c7ca,
    name: "resend-confirmation"
  }, {
    path: "/reset-password",
    component: _3113c175,
    name: "reset-password"
  }, {
    path: "/settings",
    component: _6ccb323f,
    name: "settings",
    children: [{
      path: "close-account",
      component: _1fcf2d08,
      name: "settings-close-account"
    }, {
      path: "export",
      component: _7c568434,
      name: "settings-export"
    }, {
      path: "hospitals",
      component: _020f9a6c,
      name: "settings-hospitals"
    }, {
      path: "options",
      component: _79a3ff4e,
      name: "settings-options"
    }, {
      path: "pharmacies",
      component: _9e338ade,
      name: "settings-pharmacies"
    }, {
      path: "security",
      component: _62fce7c0,
      name: "settings-security"
    }, {
      path: "user",
      component: _60d952cb,
      name: "settings-user"
    }, {
      path: "hospitals/new",
      component: _570f1cba,
      name: "settings-hospitals-new"
    }, {
      path: "hospitals/:id",
      component: _597d9f57,
      name: "settings-hospitals-id"
    }, {
      path: "hospitals/:id/edit",
      component: _931c85d6,
      name: "settings-hospitals-id-edit"
    }]
  }, {
    path: "/wrapped",
    component: _5fd62f19,
    name: "wrapped"
  }, {
    path: "/embed/rx",
    component: _5bead250,
    name: "embed-rx"
  }, {
    path: "/prescriptions/new",
    component: _7be05184,
    name: "prescriptions-new"
  }, {
    path: "/redirect/new",
    component: _07139009,
    name: "redirect-new"
  }, {
    path: "/",
    component: _706d5834,
    name: "index"
  }, {
    path: "/paylinks/:slug",
    component: _c378b2b0,
    name: "paylinks-slug"
  }, {
    path: "/prescriptions/:signature",
    component: _429e638b,
    name: "prescriptions-signature"
  }, {
    path: "/new-password/:uid?/:token?",
    component: _3a3e44ed,
    name: "new-password-uid-token"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
